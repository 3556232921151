import React from 'react';
import Link from 'gatsby-link';
import Helmet from 'react-helmet';

import notFoundImg from '../assets/img/notfound-160x160.svg';

const NotFoundPage = () => (
  <div className="not-found-wrapper">
    <div>
      <Helmet title="Battistutta | Pagina non trovata" />
    </div>

    {/* <div className="not-found"> */}
    <div className="not-found-center">
      <img title="404" className="not-found-hero" src={notFoundImg} />
      <h1 className="not-found-title-page">Pagina non trovata</h1>
      <p className="not-found-description-page">
        Uffa! La pagina che stai cercando non è stata trovata.
      </p>
      <Link className="btn-secondary caps" to="/">
        Ritorna alla Home
      </Link>
    </div>
  </div>
);

export default NotFoundPage;
